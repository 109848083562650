
import {
  defineComponent,
  onBeforeUnmount,
  onMounted,
  PropType,
  ref,
  watchEffect
} from 'vue';
import videojs, { VideoJsPlayer } from 'video.js';
import 'video.js/dist/video-js.css';
import '@videojs/themes/dist/forest/index.css';

export default defineComponent({
  name: 'VideoPlayer',
  props: {
    autoplay: { type: Boolean, default: false },
    controls: { type: Boolean, default: false },
    src: {
      type: [String, Object] as PropType<string | { src: string; type: string }>,
      validate: (value: string | Record<string, string>) =>
        typeof value === 'string' ||
        (typeof value.src === 'string' && typeof value.type === 'string')
    }
  },

  emits: ['ended'],

  setup(props) {
    const videoPlayer = ref<HTMLVideoElement | null>(null);
    let player: VideoJsPlayer | null = null;

    watchEffect(() => player?.autoplay(props.autoplay));
    watchEffect(() => player?.controls(props.controls));
    watchEffect(() => props.src && player?.src(props.src));

    onMounted(() => {
      if (videoPlayer.value === null) {
        throw new Error('Video Player missing');
      }
      player = videojs(videoPlayer.value, {
        autoplay: props.autoplay,
        controls: props.controls
      });
      if (props.src !== undefined) {
        player.src(props.src);
      }
    });

    onBeforeUnmount(() => {
      if (player !== null) {
        player.dispose();
      }
    });

    return {
      videoPlayer,
      play: () => player?.play(),
      pause: () => player?.pause()
    };
  }
});
