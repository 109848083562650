<template>
  <div>
    <div class="relative" style="padding-bottom: 75%">
      <video
        class="absolute w-full h-full object-cover video-js vjs-theme-forest"
        ref="videoPlayer"
        @ended="$emit('ended')"
      ></video>
    </div>
  </div>
</template>

<script lang="ts">
import {
  defineComponent,
  onBeforeUnmount,
  onMounted,
  PropType,
  ref,
  watchEffect
} from 'vue';
import videojs, { VideoJsPlayer } from 'video.js';
import 'video.js/dist/video-js.css';
import '@videojs/themes/dist/forest/index.css';

export default defineComponent({
  name: 'VideoPlayer',
  props: {
    autoplay: { type: Boolean, default: false },
    controls: { type: Boolean, default: false },
    src: {
      type: [String, Object] as PropType<string | { src: string; type: string }>,
      validate: (value: string | Record<string, string>) =>
        typeof value === 'string' ||
        (typeof value.src === 'string' && typeof value.type === 'string')
    }
  },

  emits: ['ended'],

  setup(props) {
    const videoPlayer = ref<HTMLVideoElement | null>(null);
    let player: VideoJsPlayer | null = null;

    watchEffect(() => player?.autoplay(props.autoplay));
    watchEffect(() => player?.controls(props.controls));
    watchEffect(() => props.src && player?.src(props.src));

    onMounted(() => {
      if (videoPlayer.value === null) {
        throw new Error('Video Player missing');
      }
      player = videojs(videoPlayer.value, {
        autoplay: props.autoplay,
        controls: props.controls
      });
      if (props.src !== undefined) {
        player.src(props.src);
      }
    });

    onBeforeUnmount(() => {
      if (player !== null) {
        player.dispose();
      }
    });

    return {
      videoPlayer,
      play: () => player?.play(),
      pause: () => player?.pause()
    };
  }
});
</script>

<style lang="postcss">
.vjs-theme-forest {
  --vjs-theme-forest--primary: #4c55a5;
}

.video-js {
  position: absolute;
}

.vjs-theme-forest {
  &:hover .vjs-big-play-button {
    background: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='88' height='88' fill='%234c55a5'%3E%3Cpath fill-rule='evenodd' d='M44 88C19.738 88 0 68.262 0 44S19.738 0 44 0s44 19.738 44 44-19.738 44-44 44zm0-85C21.393 3 3 21.393 3 44c0 22.608 18.393 41 41 41s41-18.392 41-41C85 21.393 66.607 3 44 3zm16.063 43.898L39.629 60.741a3.496 3.496 0 01-3.604.194 3.492 3.492 0 01-1.859-3.092V30.158c0-1.299.712-2.483 1.859-3.092a3.487 3.487 0 013.604.194l20.433 13.843a3.497 3.497 0 01.001 5.795zm-1.683-3.311L37.946 29.744a.49.49 0 00-.276-.09.51.51 0 00-.239.062.483.483 0 00-.265.442v27.685c0 .262.166.389.265.442.1.053.299.118.515-.028L58.38 44.414A.489.489 0 0058.6 44a.49.49 0 00-.22-.413z'/%3E%3C/svg%3E");
  }
}

.vjs-theme-forest .vjs-big-play-button {
  &:focus {
    background: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='88' height='88' fill='%234c55a5'%3E%3Cpath fill-rule='evenodd' d='M44 88C19.738 88 0 68.262 0 44S19.738 0 44 0s44 19.738 44 44-19.738 44-44 44zm0-85C21.393 3 3 21.393 3 44c0 22.608 18.393 41 41 41s41-18.392 41-41C85 21.393 66.607 3 44 3zm16.063 43.898L39.629 60.741a3.496 3.496 0 01-3.604.194 3.492 3.492 0 01-1.859-3.092V30.158c0-1.299.712-2.483 1.859-3.092a3.487 3.487 0 013.604.194l20.433 13.843a3.497 3.497 0 01.001 5.795zm-1.683-3.311L37.946 29.744a.49.49 0 00-.276-.09.51.51 0 00-.239.062.483.483 0 00-.265.442v27.685c0 .262.166.389.265.442.1.053.299.118.515-.028L58.38 44.414A.489.489 0 0058.6 44a.49.49 0 00-.22-.413z'/%3E%3C/svg%3E");
  }

  &:focus-visible {
    @apply outline-none ring-2 ring-blue;
  }

  &:-moz-focusring {
    @apply outline-none ring-2 ring-blue ring-offset-2;
  }
}
</style>
